import '../ResidentsOverview.style.scss'

import React, {useMemo} from 'react'
import Badge from '../../../components/Badge'
import Card from '../../../components/Card/Card'
import DataGrid from '../../../components/DataGrid'
import Column from '../../../components/Grid/Column'
import {convertToNiceDate} from '../../../functions'
import CrashScreen from '../../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import usePortalPersonList from '../../../hooks/data/usePortalPersonList'
import {
  TAccessTypeCode,
  TResidentTypeCode,
} from '../../../data/graphql/queries/common/types'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import {useNavigate, useSearchParams} from 'react-router-dom'

type HouseholdDataItem = {
  name: React.ReactNode
  userId: number
  status: React.ReactNode
  dateAdded: string
}

const Household = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const unitId = searchParams.get('unitId') || ''

  const [householdList] = usePortalPersonList({
    condition: {
      personAccessCode: TAccessTypeCode.R,
      isPersonAccessActive: true,
      residentType: TResidentTypeCode.HOH,
      unitId: parseInt(unitId),
      isLockAccess: true,
    },
  })

  const householdData = useMemo((): HouseholdDataItem[] => {
    if (!householdList) {
      return []
    }

    return householdList.map(item => ({
      userId: item.personId,
      name: <CellWithAvatar name={item.personName} />,
      status: (
        <Badge size={'xs'} theme={item.isPersonAccessActive ? 'info' : 'default'}>
          {item.isPersonAccessActive ? 'Active' : 'Inactive'}
        </Badge>
      ),
      dateAdded: convertToNiceDate(item.lockScheduleStartDt) || '-',
    }))
  }, [householdList])

  const openHohDetails = (index: number) => {
    const {userId} = householdData[index]

    navigate(`/people/residents/${userId}/overview?unitId=${unitId}`)
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <Card padding='sm'>
        <div className='pl-20'>
          <Column>
            <h6 className='d-flex'>Household</h6>
            <DataGrid
              minHeight
              selectableRows
              columns={[
                {key: 'name', name: 'Name'},
                {key: 'status', name: 'Status'},
                {key: 'dateAdded', name: 'Date Added'},
              ]}
              rows={householdData}
              onRowSelect={openHohDetails}
            />
          </Column>
        </div>
      </Card>
    </ErrorBoundary>
  )
}

export default Household
